<template>
  <div>
    <publication></publication>
  </div>
</template>

<script>
import Publication from '../components/Publication';

export default {
  name: 'PublicationView',
  components: {
    Publication,
  }
};
</script>

<style scoped>
</style>
