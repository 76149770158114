<template>
  <div>
    <sign-up></sign-up>
  </div>
</template>

<script>
import SignUp from '../components/SignUp'

export default {
  name: 'SignupView',
  components: {
    SignUp,
  },
}
</script>

<style scoped>

</style>