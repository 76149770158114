<template>
  <div>
    <gallery></gallery>
  </div>
</template>

<script>
import Gallery from '../components/Gallery';

export default {
  name: 'GalleryView',
  components: {
    Gallery,
  }
};
</script>

<style scoped>
</style>
