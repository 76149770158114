<template>
  <div>
    <forgotten-password></forgotten-password>
  </div>
</template>

<script>
import ForgottenPassword from '../components/ForgottenPassword.vue'

export default {
  name: 'ForgottenPasswordView',
  components: {
    ForgottenPassword
  },
}
</script>

<style scoped>

</style>