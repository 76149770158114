<template>
  <div>
    <sign-in></sign-in>
  </div>
</template>

<script>
import SignIn from '../components/SingIn'; 

export default {
  name: 'SigninView',
  components: {
    SignIn,
  },
}
</script>

<style scoped>

</style>