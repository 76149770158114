<template>
  <div>
    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from '../components/ContactUs';

export default {
  name: "ContactView",
  components: {
    ContactUs,
  }
};
</script>

<style scoped>
</style>
