<template>
  <div class="footer-box">
    <hr />
    <div class="footer-content">
      16227, 경기 수원시 영통구 광교산로 154-42 (이의동 산94-6) 8강의동(육영관)
      5층 8506 네트워크 산학협력 연구실 <br />
      Copyright &copy; 2020 by Smart IoT Lab All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>

<style scoped>
.footer-box {
  width: 100%;
  height: 100px;
}
.footer-content {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 1.7vh;
  color: #777;
}
</style>
