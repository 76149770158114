<template>
  <my-page-before-check></my-page-before-check>
</template>

<script>
import MyPageBeforeCheck from '../components/MyPageBeforeCheck';

export default {
  name: 'MyPageBeforeCheckView',
  components: {
    MyPageBeforeCheck,
  }
}
</script>

<style>

</style>