<template>
  <div>
    <div>
      <p class="cne">
        Welcome to the Smart I.O.T Lab
        <b-icon icon="emoji-smile"> </b-icon>
      </p>
      <p class="pp">
        Kyonggi University Smart Internet of Things Laboratory
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cne {
  margin: 0 auto;
  font-size: 3.42vh;
  font-weight: bold;
}
.pp {
  font-size: 2vh;
  color: #808080;
}
</style>
